<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="门店名称">
          <store-list-select v-model="query.shopId"></store-list-select>
        </query-item>
        <query-item label="姓名">
          <a-input v-model="query.userName" placeholder="姓名"></a-input>
        </query-item>
        <query-item label="手机号">
          <a-input v-model="query.loginName" placeholder="账号"></a-input>
        </query-item>
        <query-item label="创建时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>

    <!-- 修改密码弹出层 开始-->
    <a-modal
      title="修改密码"
      :visible="changePwdVisible"
      :maskClosable="false"
      @ok="submitForm('ruleForm')"
      @cancel="handleCancel">
      <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
        <a-form-model-item label="新密码" prop="password">
          <a-input v-model="ruleForm.password" type="password" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item label="确认新密码" prop="password1">
          <a-input v-model="ruleForm.password1" type="password" autocomplete="off" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 修改密码弹出层 结束-->
  </div>
</template>

<script>
import { systemUserList, systemUserStatus, sysUserUpdatepwd } from '@/api/user';
import queryMixin from '@/mixin/queryMixin';
import IDatePicker from '../../components/IDatePicker.vue';

export default {
  name: 'RoleList',
  mixins: [queryMixin],
  components: {
    IDatePicker
  },
  props: {},
  data() {
    let validatePass = (rule, value, callback) => {
      if (this.ruleForm.password1 && value !== this.ruleForm.password1) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (this.ruleForm.password && value !== this.ruleForm.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    };
    return {
      query: {
        shopId: null,
        loginName: null,
        userName: null,
        duration: [],
      },
      currentUser: null,
      changePwdVisible: false,
      ruleForm: {
        password: '',
        password1: '',
      },
      rules: {
        password: [{ required: true, message: '请输入新密码', }, { validator: validatePass, trigger: 'blur' }],
        password1: [{ required: true, message: '请输入确认密码', }, { validator: validatePass2, trigger: 'blur' }],
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      // 默认展示表格
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '账号',
          dataIndex: 'loginName',
          key: 'loginName',
          ellipsis: true,
          width: 150,
        },
        {
          title: '姓名',
          dataIndex: 'userName',
          key: 'userName',
          ellipsis: true,
          width: 100,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
          width: 150,
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '性别',
          dataIndex: 'sexName',
          key: 'sexName',
          ellipsis: true,
          width: 100,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          ellipsis: true,
          // width: 160,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 120,
          customRender: (text, row, index) => {
            return (
              <a-switch checked-children="启用" un-checked-children="禁用" checked={row.status === 0} onClick={ () => this.handleStatus(row)}/>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 220,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleInfo(row) }>查看</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleEdit(row) }>修改</a-button>
                <a-divider type="vertical"></a-divider>
                <a-button type="link" size="small" onClick={ () => this.handleReset(row) }>修改密码</a-button>
              </section>
            );
          }
        }
      ],
      dataList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      this.dataList = [];
      systemUserList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        this.dataList = list;
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'userAdd'
      });
    },

    // 查看
    handleInfo(row) {
      this.$router.push({
        name: 'userInfo',
        params: {
          id: row.userId
        }
      });
    },

    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'userEdit',
        params: {
          id: row.userId
        }
      });
    },


    // 重置密码
    handleReset(row) {
      this.currentUser = row;
      this.changePwdVisible = true;
    },

    // 提交新密码
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {
            password: this.ruleForm.password,
            password1: this.ruleForm.password1,
            userId: this.currentUser.userId,
          };
          sysUserUpdatepwd(params).then(data => {
            this.$message.success('操作成功!');
            this.handleCancel();
          });
        } else {
          return false;
        }
      });
    },
    // 取消修改密码
    handleCancel() {
      this.resetForm('ruleForm');
      this.changePwdVisible = false;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleStatus(row) {
      const params = {
        userId: row.userId,
        status: row.status === 0 ? 1 : 0
      };

      systemUserStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
